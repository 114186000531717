import axios from 'axios'

const sendResults = ({ payload }) => new Promise((resolve, reject) => {
  console.log(payload)
  axios.post(process.env.REACT_APP_SEND_RESULTS_URL, {
    payload
  }).then(result => {
    console.log(result)
    resolve(result)
  }).catch(error => {
    console.error(error)
    reject(error)
  })
})

export { sendResults }

