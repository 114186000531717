// https://drive.google.com/file/d/11YxHstwAB6Gw38VVyGL5-fll1Da1ewSB/view?usp=sharing
// https://nmkmarquez.typeform.com/to/Xl4zxQ

import React, { useState, useEffect } from 'react'
import { BeforeQuestions, AfterQuestions } from './Questions.js'
import { sendResults } from './sendResults.js'
import { getHashParamValueFromUrl } from './utils.js'

const surveySource = getHashParamValueFromUrl('source')

const SurveyResults = {
  name: '',
  email: '',
  treatmentCount: '',
}

var slideCount = 6
if (surveySource === 'tradeshow') {
  slideCount = 6
}

const setSurveyResults = key => value => {
  SurveyResults[key] = value
}

function App () {
  const [currentSlide, setCurrentSlide] = useState(1)
  const [submittingResult, setSubmittingResult] = useState(false)
  const [answeredAllBefore, setAnsweredAllBefore] = useState(false)
  const [answeredAllAfter, setAnsweredAllAfter] = useState(false)

  // const styles = { height: '100vh', width: '100vw', margin: 'auto', maxWidth: '100%' }
  const styles = { margin: 'auto', maxWidth: '100%' }

  useEffect(() => {
    BeforeQuestions.forEach((question) => {
      const img = new Image();
      img.src = question.img;
      window[question.img] = img;
    });
    AfterQuestions.forEach((question) => {
      const img = new Image();
      img.src = question.img;
      window[question.img] = img;
    });
    const otherImages = [
      '/logo.svg',
      'explaination-1.png',
      'explaination-2.png',
      'explaination-3.png',
    ]
    otherImages.forEach((otherImage) => {
      const img = new Image();
      img.src = otherImage;
      window[otherImage] = img;
    });
  }, [])

  const AppLogo = ({ width }) => (
    <div>
      <img alt="Tomiscan" className="m-auto" style={{ width, maxWidth: '100%' }} src="/logo.svg" />
    </div>
  )

  const PreviousNext = ({ valid }) => (
    <div className="w-full mt-8">
      <div className="flex justify-center">
        {currentSlide === slideCount && <button
          style={{ backgroundColor: '#0071ce' }}
          onClick={() => {
            startOver()
          }}
          className="w-64 text-white text-2xl mt-16 py-2 rounded-lg w-24">
          Start Over
        </button>}

        {(currentSlide !== 1 && currentSlide !== slideCount) && <button
          style={{ backgroundColor: '#0071ce' }}
          onClick={previousSlide}
          className="w-64 text-white text-2xl mt-16 py-2 rounded-lg w-24">
          Previous
        </button>}

        {currentSlide !== slideCount && <button
          style={{ backgroundColor: valid ? '#0071ce' : '#9e9e9e' }}
          onClick={() => {
            if (valid) { nextSlide() }
          }}
          className="w-64 ml-4 text-white text-2xl mt-16 py-2 rounded-lg w-24">
          {currentSlide === 1 ? 'Get Started' : 'Next'}
        </button>}
      </div>

      <div className="mt-6 text-2xl text-center">
        {currentSlide} / {slideCount}
      </div>
    </div>
  )

  const SlideOne = () => {
    const keypressNextSlide = (e) => {
      let keycode = e.key
      if (keycode === 'Enter') {
        nextSlide()
      }
    }

    useEffect(() => {
      document.body.addEventListener('keydown', keypressNextSlide)

      // cleanup this component
      return () => {
        document.body.removeEventListener('keydown', keypressNextSlide)
      };
    }, [])

    return (
      <div
        style={styles}
        className="quiz-item flex flex-col items-center justify-center pt-32">
        <AppLogo width='800px' />
        <p className="text-4xl py-8 text-center text-orange">
          OtoSight Challenge
        </p>

        <PreviousNext valid={true} />
      </div>
    )
  }

  const SlideTwo = () => {
    const [valid, setValid] = useState(SurveyResults.treatmentCount > 0)

    const keypressNextSlide = (e) => {
      let keycode = e.key
      if (keycode === 'Enter' && SurveyResults.treatmentCount > 0) {
        nextSlide()
      }
    }

    useEffect(() => {
      document.body.addEventListener('keydown', keypressNextSlide)

      let firstInput = document.getElementById('treatment-count')
      if (firstInput) { firstInput.focus() }

      // cleanup this component
      return () => {
        document.body.removeEventListener('keydown', keypressNextSlide)
      };
    }, [])

    return (
      <div
        style={styles}
        className="quiz-item flex flex-col justify-center">
        <AppLogo width='300px' />

        <div className="mt-16">

          {/* { surveySource !== 'tradeshow' && <div className="mb-10">
            <label htmlFor="survey-name" className="block text-3xl">Name:</label>
            <div className="mt-1">
              <input type="text"
                    name="survey-name"
                    id="survey-name"
                    className="py-3 px-4 block w-full border-b border-solid border-black"
                    onChange={evt => { setSurveyResults('name')(evt.target.value) }}
                    defaultValue={SurveyResults.name} />
            </div>
          </div> } */}

          <div className="mb-10">
            <label htmlFor="treatment-count" className="block text-3xl">How many kids do you see a year with the presentation of otitis media on average?</label>
            <div className="mt-1">
              <input type="number"
                    name="treatment-count"
                    id="treatment-count"
                    className="py-3 px-4 block w-full border-b border-solid border-black"
                    onChange={evt => { setSurveyResults('treatmentCount')(evt.target.value); setValid(evt.target.value > 0) }}
                    defaultValue={SurveyResults.treatmentCount} />
            </div>
          </div>

          {/* { surveySource !== 'tradeshow' && <div className="mb-10">
            <label htmlFor="survey-email" className="block text-3xl">Email:</label>
            <div className="mt-1">
              <input type="text"
                    name="survey-email"
                    id="survey-email"
                    className="py-3 px-4 block w-full border-b border-solid border-black"
                    onChange={evt => { setSurveyResults('email')(evt.target.value) }}
                    defaultValue={SurveyResults.email} />
            </div>
          </div> } */}

        </div>

        <PreviousNext valid={valid} />
      </div>
    )
  }

  const setAnswer = (setStateAnswer, question, answer, collectionName) => {
    question.answer = answer
    setStateAnswer(answer)
    if (collectionName === 'before') {
      let answeredAll = true
      for (let i = 0; i < BeforeQuestions.length; i++) {
        if (!BeforeQuestions[i].answer.length) {
          answeredAll = false
        }
      }
      setAnsweredAllBefore(answeredAll)
    }
    else if (collectionName === 'after') {
      let answeredAll = true
      for (let i = 0; i < AfterQuestions.length; i++) {
        if (!AfterQuestions[i].answer.length) {
          answeredAll = false
        }
      }
      setAnsweredAllAfter(answeredAll)
    }
  }

  const Question = ({ question, collectionName }) => {
    const [answer, setStateAnswer] = useState(question.answer)

    const baseAnswerClasses = `bg-gray-500 h-8 w-8 rounded-full absolute left-0`
    const selectedAnswerClasses = `border-8 border-solid border-green-500`
    const answerLabelClasses = `w-full text-center flex items-center justify-center h-8`

    const imgClasses = `w-full border-8 border-solid`

    return (
      <div className="p-4">
        <div className="w-full">
          <div>
            <img
              alt="Tomiscan"
              className={imgClasses}
              src={question.img} />
          </div>
          <div>
            <ul className="mt-2">
              <li
                onClick={() => setAnswer(setStateAnswer, question, 'No fluid', collectionName)}
                onKeyPress={evt => {
                  if (evt.key === 'Enter' || evt.key === 'Space') {
                    setAnswer(setStateAnswer, question, 'No fluid', collectionName)
                  }
                }}
                className="flex justify-between py-2 relative cursor-pointer select-none"
                tabIndex={0}>
                <div
                  className={`${baseAnswerClasses} ${answer === 'No fluid' ? selectedAnswerClasses : ''}`}>
                </div>
                <div className={answerLabelClasses}>No fluid</div>
              </li>
              <li
                onClick={() => setAnswer(setStateAnswer, question, 'Fluid', collectionName)}
                onKeyPress={evt => {
                  if (evt.key === 'Enter' || evt.key === 'Space') {
                    setAnswer(setStateAnswer, question, 'Fluid', collectionName)
                  }
                }}
                className="flex justify-between py-2 relative cursor-pointer select-none"
                tabIndex={0}>
                <div
                  className={`${baseAnswerClasses} ${answer === 'Fluid' ? selectedAnswerClasses : ''}`}>
                </div>
                <div className={answerLabelClasses}>Fluid</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    )
  }

  const SlideThree = () => {
    const keypressNextSlide = (e) => {
      let keycode = e.key
      if (keycode === 'Enter' && answeredAllBefore) {
        nextSlide()
      }
    }

    useEffect(() => {
      document.body.addEventListener('keydown', keypressNextSlide)

      // cleanup this component
      return () => {
        document.body.removeEventListener('keydown', keypressNextSlide)
      };
    }, [])

    return (
      <div
        style={styles}
        className="quiz-item flex flex-col justify-center">
        <AppLogo width='300px' />
        <div className="mt-16">
          <p>Select the appropriate diagnosis given only the otoscopy view:</p>
          <div
            className="lg:grid lg:grid-cols-3 lg:gap-4">
            {BeforeQuestions
              .map((x, i) => (
                <Question key={i} question={x} collectionName='before' />
            ))}
          </div>
        </div>

        <PreviousNext valid={answeredAllBefore} />
      </div>
    )
  }

  const SlideFour = () => {
    const keypressNextSlide = (e) => {
      let keycode = e.key
      if (keycode === 'Enter') {
        nextSlide()
      }
    }

    useEffect(() => {
      document.body.addEventListener('keydown', keypressNextSlide)

      // cleanup this component
      return () => {
        document.body.removeEventListener('keydown', keypressNextSlide)
      };
    }, [])

    return (
      <div
        style={styles}
        className="quiz-item flex flex-col justify-center">
        <AppLogo width='300px' />

        { surveySource === 'tradeshow' && <div
          className="mt-8 lg:flex justify-center items-center mx-auto max-w-screen-lg text-center">
          <img className="inline-block max-w-full lg:w-1/3 max-h-full" src="explaination-1.png" alt="Otosight clear" />
          <img className="inline-block max-w-full lg:w-1/3 max-h-full" src="explaination-2.png" alt="Otosight OME" />
          <img className="inline-block max-w-full lg:w-1/3 max-h-full" src="explaination-3.png" alt="Otosight AOM" />
        </div> }

        { surveySource !== 'tradeshow' && <div
          style={{ height: '50vh', width: '75vw' }}
          className="bg-gray-300 mt-8 flex justify-center items-center mx-auto max-w-screen-lg">
          <iframe style={{ height: '100%', width: '100%' }} src="https://www.youtube.com/embed/21ImYkvoAfQ?autoplay=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div> }

        <PreviousNext valid={true} />
      </div>
    )
  }

  const SlideFive = () => {
    const keypressNextSlide = (e) => {
      let keycode = e.key
      if (keycode === 'Enter' && answeredAllAfter) {
        nextSlide()
      }
    }

    useEffect(() => {
      document.body.addEventListener('keydown', keypressNextSlide)

      // cleanup this component
      return () => {
        document.body.removeEventListener('keydown', keypressNextSlide)
      };
    }, [])

    return (
      <div
        style={styles}
        className="quiz-item flex flex-col justify-center">
        <AppLogo width='300px' />
        <div className="mt-16">
          <p>Now that you know the power of the OtoScan, select the appropriate diagnosis from the given otoscopy and Otoscan Views:</p>
          <div
            className="lg:grid lg:grid-cols-3 lg:gap-4">
            {AfterQuestions
              .map((x, i) => (
                <Question key={i} question={x} collectionName='after' />
            ))}
          </div>
        </div>

        <PreviousNext valid={answeredAllAfter} />
      </div>
    )
  }

  const QuestionResult = ({ beforeQuestion, afterQuestion }) => {
    const imgClasses = `w-full border-8 border-solid`

    return (
      <div className="p-4">
        <div className="w-full">
          <div>
            <img
              alt="Tomiscan"
              className={imgClasses}
              src={afterQuestion.img} />
          </div>
          <div>
            <ul className="text-left">
              <li className="mt-4">
                The correct answer was: <span class="font-bold">{afterQuestion.correctAnswer}</span>
              </li>
              <li className="mt-4">
                {beforeQuestion.answer === beforeQuestion.correctAnswer && <span className="text-green-500"><svg className="inline-block h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/></svg></span>}{beforeQuestion.answer !== beforeQuestion.correctAnswer && <span className="inline-block h-6 w-6 text-center text-red-500">X</span>}<span class="font-bold">&nbsp;Without OtoSight:</span> {beforeQuestion.answer}
              </li>
              <li className="mt-4">
                {afterQuestion.answer === afterQuestion.correctAnswer && <span className="text-green-500"><svg className="inline-block h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/></svg></span>}{afterQuestion.answer !== afterQuestion.correctAnswer && <span className="inline-block h-6 w-6 text-center text-red-500">X</span>}<span class="font-bold">&nbsp;With OtoSight:</span> {afterQuestion.answer}
              </li>
            </ul>
          </div>
        </div>
      </div>
    )
  }

  const calcResults = () => {
    let beforeQuestionRightCount = 0
    for (let i = 0; i < BeforeQuestions.length; i++) {
      if (BeforeQuestions[i].correctAnswer === BeforeQuestions[i].answer) {
        beforeQuestionRightCount++
      }
    }
    const beforeAccuracy = beforeQuestionRightCount / BeforeQuestions.length

    let afterQuestionRightCount = 0
    for (let i = 0; i < AfterQuestions.length; i++) {
      if (AfterQuestions[i].correctAnswer === AfterQuestions[i].answer) {
        afterQuestionRightCount++
      }
    }
    const afterAccuracy = afterQuestionRightCount / AfterQuestions.length

    const improvement = afterAccuracy - beforeAccuracy

    const normalizedTreatmentCount = SurveyResults.treatmentCount > 0 ? SurveyResults.treatmentCount : 0
    const misdiagnosed = Math.round(normalizedTreatmentCount * improvement)

    return {beforeAccuracy, afterAccuracy, improvement, misdiagnosed}
  }

  const SlideSix = () => {
    const {improvement, misdiagnosed} = calcResults()

    return (
      <div
        style={styles}
        className="quiz-item flex flex-col justify-center">
        <AppLogo width='300px' />

        <div className="mt-8 text-center">

          { submittingResult && <div>
            Processing results...
          </div> }

          { !submittingResult && <div>
            <div>
              <p>Congratulations. You've completed the OtoSight Challenge.</p>
              {improvement > 0 && <div>
                <p>&nbsp;</p>
                <p className='text-orange'><b>Based on your practice, OtoSight may impact your choice of treatment on an estimated { misdiagnosed } kids you see annually.</b></p>
              </div>}
              <p>&nbsp;</p>
              <p>OtoSight demonstrates objective, visual proof to reinforce the confidence parents have in your treatment plan for their most precious possession.</p>
            </div>

            { surveySource !== 'tradeshow' && <div className="text-center mt-8 mb-8">
                <p>To learn more go to <a href="http://www.photoni.care" target="_blank" rel="noopener noreferrer">www.photoni.care</a> or <a href="http://photoni.care/otosight/clinicians/" target="_blank" rel="noopener noreferrer">click here</a></p>
              </div> }

            <div className="mt-16">
              <h2 className="text-2xl">Here is how you did:</h2>
              <div
                className="lg:grid lg:grid-cols-3 lg:gap-4">
                {AfterQuestions
                  .map((x, i) => (
                    <QuestionResult key={i} beforeQuestion={BeforeQuestions[i]} afterQuestion={x} />
                ))}
              </div>
            </div>
          </div> }

        </div>

        <PreviousNext valid={true} />

        { surveySource !== 'tradeshow' && <div className="text-center mt-8 mb-16">
            <p>To learn more go to <a href="http://www.photoni.care" target="_blank" rel="noopener noreferrer">www.photoni.care</a> or <a href="http://photoni.care/otosight/clinicians/" target="_blank" rel="noopener noreferrer">click here</a></p>
          </div> }
      </div>
    )
  }

  const isCurrent = x => (x === currentSlide)

  const startOver = () => {
    for (let property in SurveyResults) {
      SurveyResults[property] = ''
    }
    BeforeQuestions.map(x => {
      x.answer = ''
      return x
    })
    setAnsweredAllBefore(false)
    AfterQuestions.map(x => {
      x.answer = ''
      return x
    })
    setAnsweredAllAfter(false)
    setCurrentSlide(1)
  }

  const previousSlide = () => {
    setCurrentSlide(Math.max(1, currentSlide - 1))
  }

  const nextSlide = () => {
    setCurrentSlide(Math.min(slideCount, currentSlide + 1))

    if (currentSlide + 1 === slideCount) {
      // last slide so calculate and submit results
      setSubmittingResult(true)

      const {beforeAccuracy, afterAccuracy} = calcResults()

      sendResults({
        payload: {
          source: surveySource,
          survey: SurveyResults,
          quizResults: {
            before: BeforeQuestions,
            after: AfterQuestions,
          },
          beforeAccuracy: (Math.round(beforeAccuracy * 100) / 100),
          afterAccuracy: (Math.round(afterAccuracy * 100) / 100),
        }
      }).then(result => {
        setSubmittingResult(false)
      }).catch(error => {
        // we dont do anything if it fails to submit since the user doesn't care and pcare doesnt care if the results are lost if their internet connection is bad
        setSubmittingResult(false)
      })
    }

    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  return (
    <div
      className="container mx-auto mt-16 p-4">
      <div>
        { isCurrent(1) && <SlideOne /> }
        { isCurrent(2) && <SlideTwo /> }
        { isCurrent(3) && <SlideThree /> }
        { isCurrent(4) && <SlideFour /> }
        { isCurrent(5) && <SlideFive /> }
        { isCurrent(6) && <SlideSix /> }
      </div>
    </div>
  )
}

export default App
