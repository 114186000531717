export const BeforeQuestions = [
    {
      correctAnswer: 'No fluid',
      img: '2021-09-10/NF1.png',
      answer: '',
    },
    {
      correctAnswer: 'Fluid',
      img: '2021-09-10/F1.png',
      answer: '',
    },
    {
      correctAnswer: 'No fluid',
      img: '2021-09-10/NF2.png',
      answer: '',
    },
    {
      correctAnswer: 'No fluid',
      img: '2021-09-10/NF3.png',
      answer: '',
    },
    {
      correctAnswer: 'Fluid',
      img: '2021-09-10/F2.png',
      answer: '',
    },
    {
      correctAnswer: 'Fluid',
      img: '2021-09-10/F3.png',
      answer: '',
    },
  ]

  export const AfterQuestions = [
    {
      correctAnswer: 'No fluid',
      img: '2021-09-10/pcare-nf1.png',
      answer: '',
    },
    {
      correctAnswer: 'Fluid',
      img: '2021-09-10/pcare-f1.png',
      answer: '',
    },
    {
      correctAnswer: 'No fluid',
      img: '2021-09-10/pcare-nf2.png',
      answer: '',
    },
    {
      correctAnswer: 'No fluid',
      img: '2021-09-10/pcare-nf3.png',
      answer: '',
    },
    {
      correctAnswer: 'Fluid',
      img: '2021-09-10/pcare-f2.png',
      answer: '',
    },
    {
      correctAnswer: 'Fluid',
      img: '2021-09-10/pcare-f3.png',
      answer: '',
    },
  ]